import React, { useEffect, useRef, useState } from 'react';
import { NODE_ENV } from './config/ServerConfig';
import RouterComponent from './components/router-component';
import { RouteConfig } from './utils/RouterUtil';
import { login, logout } from './api/login/LoginAPI';
import { ServerUserResponse, User, UserTypes } from './models/User';
import { checkAuth } from './api/auth/AuthAPI';
import LoaderComponent from './components/loader-component';
import { UserContext } from './contexts/UserContext';
import { rememberPreviousLink } from './utils/DeepLinkingUtil';
import { LoginFormData } from './views/login/interfaces';
import ResponseError from './errors/ResponseError';
import { createUserSessionLog } from './api/users/UsersAPI';
import { convertToLocalDate, getUtcOffset } from './utils/TimeUtil';
import { APP_VERSION } from './config/FrontConfig';
import { getVersionAPI } from './api/version/VersionAPI';
import pkj from '../package.json';
import { CommonContext, CommonContextType } from './contexts/CommonContext';
import { getSelectOptions } from './api/filters/FiltersAPI';
import { CommonData } from './api/filters/interfaces';
import { transformScanTypeData } from './views/new-scan/NewScanUtil';
import { SelectOption } from './models/General';
import { differenceInMinutes } from 'date-fns';
import { REUPLOAD_INTERVAL_IN_MINUTES } from './utils/Constants';

const App: React.FunctionComponent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('isAuth'));
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isReloadPage, setIsReloadPage] = useState<boolean>(false);
  const [isReloading, setIsReloading] = useState<boolean>(true);
  const [isBlur, setIsBlur] = useState<boolean>(false);
  const [commonData, setCommonData] = useState<Omit<CommonContextType, 'updateCommonData'>>({
    TasksStatuses: [],
    TasksTypes: [],
    ScanStatuses: [],
    Languages: [],
    Genders: [],
    InsurancePlans: [],
    ScanTypes: [],
  });
  const [user, setUser] = useState<User | null>(null);
  const currentUserID = useRef<number | null>(null);
  const previousTimeReloaded = useRef<Date>(new Date());

  const getAllData = () => {
    getSelectOptions<CommonData>(['ScanStatuses', 'TasksStatuses', 'TasksTypes', 'InsurancePlans', 'Languages', 'Genders', 'ScanList'])
      .then(res => {
        previousTimeReloaded.current = new Date();
        const filledStatusesArray: SelectOption[] = [];
        const filledTaskStatusesArray: SelectOption[] = [];
        const filledLanguagesArray: SelectOption[] = [];
        const filledGendersArray: SelectOption[] = [];
        const filledTypesArray: SelectOption[] = [];

        Object.values(res.ScanStatuses).map(el => {
          filledStatusesArray.push({
            label: el.Status === 'Cancelled' ? 'Closed' : el.Status,
            value: el.ID,
          });
        });

        Object.values(res.TasksStatuses).map(el => {
          filledTaskStatusesArray.push({
            label: el.Name,
            value: el.ID,
          });
        });

        Object.values(res.TasksTypes).map(el => {
          filledTypesArray.push({
            label: el.Name,
            value: el.ID,
          });
        });

        Object.values(res.Languages).forEach(el => {
          filledLanguagesArray.push({
            label: el.language,
            value: el.ID,
          });
        });

        Object.values(res.Genders).forEach(el => {
          filledGendersArray.push({
            label: el.Gender,
            value: el.ID,
          });
        });

        setCommonData({
          TasksStatuses: filledTaskStatusesArray,
          TasksTypes: filledTypesArray,
          ScanStatuses: filledStatusesArray,
          Languages: filledLanguagesArray,
          Genders: filledGendersArray,
          InsurancePlans: res.InsurancePlans,
          ScanTypes: transformScanTypeData(res.ScanList),
        });
      })
      .catch(() => {
        setCommonData({
          TasksStatuses: [],
          TasksTypes: [],
          ScanStatuses: [],
          Languages: [],
          Genders: [],
          InsurancePlans: [],
          ScanTypes: [],
        });
      });
  };

  useEffect(() => {
    const APP_INFO = { APP: APP_VERSION === '0.0.0' ? pkj.version : APP_VERSION, API: '' };
    getVersionAPI()
      .then(r => (APP_INFO.API = r.info))
      .catch(e => console.error(e))
      .finally(() => (window.medmo_version = JSON.stringify(APP_INFO)));
  }, []);

  const checkUser = (data: ServerUserResponse, isRemember?: boolean): Promise<ServerUserResponse> => {
    return new Promise((resolve, reject) => {
      if (data?.status && data?.status > 200) {
        localStorage.removeItem('isAuth');
        reject(data);
        return;
      }

      if (currentUserID.current) {
        if (data.UserID === currentUserID.current) {
          setIsBlur(false);
        } else {
          currentUserID.current = null;
          resolve(data);
          window.location.reload();
          return;
        }
      }

      const roleNumber: number | undefined = UserTypes[data?.UserType] as number;
      setIsAdmin(roleNumber === 4);

      if ((data.IsMemberOfParentAccount || data.UserID !== currentUserID.current) && roleNumber !== 4) {
        getAllData();
      }

      data.IsCleerly = data?.PO?.AllowCleerlyOrders === 1;

      !isAuthenticated && setIsAuthenticated(true);
      isRemember && localStorage.setItem('isAuth', 'true');
      !isRemember && localStorage.setItem('isAuth', 'true');
      setUser(data);
      currentUserID.current = data.UserID;
      resolve(data);
    });
  };

  const updateUser = (isAuth?: string): Promise<any> => {
    return checkAuth()
      .then(res => {
        if (res && res.status) {
          localStorage.clear();
          return;
        }
        return checkUser(res, true);
      })
      .catch(error => {
        setIsBlur(false);
        if (error.message === 'Failed to fetch') {
          isAuth && localStorage.setItem('isAuth', isAuth);
          throw error;
        }

        localStorage.removeItem('isAuth');

        if (error.status && error.status === 404) {
          const preparedError = { ...error, status: 401 };

          localStorage.setItem('isSessionExpired', 'true');

          throw new ResponseError(preparedError);
        }
      })
      .finally(() => setIsReloading(false));
  };

  const expandUser = (data: Record<string, any>) => {
    if (user) {
      setUser({ ...user, ...data });
    }
  };

  useEffect(() => {
    const isAuth = localStorage.getItem('isAuth');
    setIsAdmin(false);
    setIsReloading(!!isAuth);
    if (isAuth === 'true') {
      localStorage.removeItem('isAuth');
      setIsReloadPage(true);
      setIsAuthenticated(true);
      updateUser(isAuth);
    } else {
      rememberPreviousLink(window.location);
    }
  }, []);

  useEffect(() => {
    if (['local-test', 'local'].includes(NODE_ENV)) {
      return;
    }

    window.addEventListener('focus', pageFocusHandler);
    return () => {
      window.removeEventListener('focus', pageFocusHandler);
    };
  }, []);

  const pageFocusHandler = () => {
    const isAuth = localStorage.getItem('isAuth');
    if (currentUserID.current) {
      setIsBlur(!!isAuth);
      updateUser(isAuth ? 'true' : '');
    }
  };

  const createSessionLog = () => {
    const sessionStart = convertToLocalDate(new Date());
    const utcOffset = getUtcOffset();
    createUserSessionLog(sessionStart, utcOffset).then();
  };

  const authCheck = (data: LoginFormData) => {
    return new Promise((resolve, reject) => {
      login(data)
        .then(res => {
          if (res.status && res.status > 200) {
            return reject(res);
          }
          checkUser(res, data.remember);
          const roleNumber: number | undefined = UserTypes[res.UserType] as unknown as number;
          createSessionLog();
          resolve({ ...res, isAdmin: roleNumber === 4 });
        })
        .catch(e => {
          localStorage.removeItem('isAuth');
          reject(e);
        });
    });
  };

  const logOut = async () => {
    logout().finally(() => {
      setUser(null);
      currentUserID.current = null;
      setIsAuthenticated(false);
      setIsAdmin(false);
      setIsReloadPage(false);
      localStorage.removeItem('isAuth');
      localStorage.removeItem('route');
    });
  };

  const changeReload = (isReload: boolean) => {
    setIsReloadPage(isReload);
  };

  const updateCommonData = () => {
    if (differenceInMinutes(new Date(), previousTimeReloaded.current) < REUPLOAD_INTERVAL_IN_MINUTES) {
      return;
    }

    getAllData();
  };

  return (
    <>
      <div className='wrapper'>
        {isReloading ? (
          <main className='main'>
            <section className='page-section'>
              <div className='container'>
                <div className='row'>
                  <LoaderComponent className={'loader-block-center'} />
                </div>
              </div>
            </section>
          </main>
        ) : (
          <UserContext.Provider value={{ ...(user as User), updateUser, expandUser }}>
            <CommonContext.Provider value={{ ...commonData, updateCommonData }}>
              <RouterComponent
                changeReload={changeReload}
                isAuthenticated={isAuthenticated}
                isAdmin={isAdmin}
                isReload={isReloadPage}
                authenticate={authCheck}
                logOut={logOut}
                items={RouteConfig}
              />
            </CommonContext.Provider>
          </UserContext.Provider>
        )}
      </div>
      {isBlur && <div className='bluring'></div>}
    </>
  );
};

export default App;

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Form, Radio, RadioGroup, Button } from 'rsuite';
import TextField from '../../../components/text-field-component';
import { Nav } from 'rsuite';
import { saveAs } from 'file-saver';
import ClinicalNotesTaskTabComponent from './ClinicalNotesTaskTabComponent';
import DetailsTaskTabComponent from './DetailsTaskTabComponent';
import { TaskStatuses, TASK_TABS_NAME, texts } from '../TasksUtil';
import { P2PtaskResponse, PeerReviewTaskFormData, TaskProps } from '../interfaces';
import { FileView } from '../../../components/file-viewer/interfaces';
import { FileType } from 'rsuite/Uploader';
import { FileTypeWithTitle, ModalError } from '../../new-scan/interfaces';
import { initPeerReviewTaskFormData } from '../task/TaskUtil';
import { checkForEmptyString, checkForWrongFile } from '../../../utils/GeneralUtil';
import InfoModalComponent from '../../../components/modals/info-modal';
import { PeerReviewTaskSchema } from '../Schemas';
import { FormInstance } from 'rsuite/Form';
import { completePeerToPeerTask, uploadTaskDocuments } from '../../../api/tasks/TasksApi';
import { FormError } from '../../../models/General';
import StaticP2PTaskComponent from './StaticPeerToPeerTaskComponent';
import { RequestFiles } from '../../../api/all-scans/interfaces';
import FilesUploader from '../../../components/files-uploader';
import Textarea from '../../../components/textarea';
import { onLoadFile, renameFile } from '../../../utils/FileUtils';
import { useSignal } from '../../../utils/UseSignal';

const PeerReviewTaskComponent: React.FC<TaskProps> = ({ taskData, liftGoBackTextUp, getAllTaskInfo }) => {
  const { special, task, testRequest, insurance, authorizationFiles, Patient } = taskData;
  const [modalError, setModalError] = useState<ModalError>({
    title: '',
    content: '',
  });
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [formData, setFormData] = useState<PeerReviewTaskFormData>(initPeerReviewTaskFormData);
  const [formError, setFormError] = useState<FormError>({});
  const [isLoading, setIsLoading] = useState(false);
  const fileLoadedCount = useRef<number>(0);
  const formRef = useRef<FormInstance>(null);
  const signal = useSignal();

  useEffect(() => {
    setActiveNav(TASK_TABS_NAME.Details);
    liftGoBackTextUp(`Peer-to-Peer review of Scan Request #${testRequest.ScanID}`);
    let authorizationDocuments: Array<FileTypeWithTitle & FileView> = [];
    if (authorizationFiles) {
      authorizationDocuments = authorizationFiles.map((file, i) => ({
        key: file.key,
        url: file.url as string,
        name: file.originalName,
        fileTitle: file.fileTitle,
        id: i,
      }));
    }
    setFormData({
      ...formData,
      currTaskID: task.ID,
      newReviewerName: checkForEmptyString((special as P2PtaskResponse).ReviewerName),
      newReviewerSpecialty: checkForEmptyString((special as P2PtaskResponse).ReviewerSpecialty),
      newReviewResult: (special as P2PtaskResponse).ReviewResult,
      newDenialReason: checkForEmptyString((special as P2PtaskResponse).DenialReason),
      newAuthorizationID: checkForEmptyString((special as P2PtaskResponse).AuthorizationID),
      authorizationDocuments,
      newTaskNotes: checkForEmptyString(task.TaskNotes),
    });
  }, [testRequest, task]);

  const isSubmitEnabled = useMemo(() => {
    return (
      !!formData.newReviewerName &&
      formData.newReviewResult !== null &&
      (formData.newReviewResult === 1 ? !!formData.newAuthorizationID || formData.authorizationDocuments.length > 0 : true)
    );
  }, [formData]);

  const checkDocuments = (files: FileTypeWithTitle[]): boolean => {
    if (files && files.length) {
      const isWrongFileExist = checkForWrongFile(files);
      if (isWrongFileExist === false) {
        return true;
      }

      setFormError({ ...formError, fileNoteName: isWrongFileExist });

      return false;
    }

    return true;
  };

  const fileLoadFinish = () => {
    if (!isLoading) {
      return;
    }
    fileLoadedCount.current += 1;
    if (fileLoadedCount.current === formData.authorizationDocuments.length) {
      fileLoadedCount.current = 0;
    }
  };

  const uploadDocuments = async (fileList: Array<FileType>) => {
    if (fileList && fileList.length) {
      const newArr: (FileTypeWithTitle & FileView)[] = [...formData.authorizationDocuments];
      for (let i = 0; i < fileList.length; i++) {
        const item: FileTypeWithTitle = fileList[i] as FileTypeWithTitle;
        if (!formData.authorizationDocuments[i]) {
          const result = await onLoadFile(item);
          item.id = i;
          item.url = result;
          item.fileTitle = item.name as string;
          item.mimeType = item.blobFile?.type;

          newArr.push(item);
        } else {
          newArr[i].id = i;
        }
      }
      setFormData({
        ...formData,
        authorizationDocuments: newArr,
      });
      setFormError({ ...formError, fileNoteName: '' });
    } else {
      setFormData({
        ...formData,
        authorizationDocuments: [],
      });
      setFormError({ ...formError, fileNoteName: '' });
    }
  };

  const downloadFile = (doc: FileTypeWithTitle): void => {
    if (!doc.blobFile) {
      saveAs(doc.url as string, doc.name);
      return;
    }
    saveAs(doc.blobFile as Blob);
  };

  const uploadFiles = async (data: FileTypeWithTitle[]) => {
    const files: FileTypeWithTitle[] = [];
    const keyFiles: RequestFiles[] = [];
    const fileData = new FormData();

    if (!data || !data.length) {
      return [];
    }

    for (let i = 0; i < data.length; i++) {
      const file: FileTypeWithTitle = data[i] as FileTypeWithTitle;
      if (file.blobFile) {
        files.push(file);
        const newFile = await renameFile(file.blobFile);
        fileData.append('files', newFile);
      }
      if (file.key) {
        keyFiles.push({
          key: file.key,
          fileTitle: file.fileTitle,
          originalName: file.name as string,
        });
      }
    }

    if (!files.length) {
      return [];
    }

    return uploadTaskDocuments(fileData)
      .then(response => {
        if (response) {
          const res: FileTypeWithTitle[] = files.map((el: { fileTitle: string }, i: number) => {
            return {
              ...response[i],
              fileTitle: el.fileTitle,
            };
          });
          return [...res, ...keyFiles];
        }
      })
      .catch(() => setModalError(texts.error.uploadDocuments));
  };

  const submit = async () => {
    if (!formRef.current || !formRef.current.check()) {
      return;
    }

    const { formError: newFormError } = await formRef.current.checkAsync();
    const { newReviewResult, newAuthorizationID, authorizationDocuments } = formData;

    if (formData && newReviewResult === 1 && !newAuthorizationID && !!authorizationDocuments && !authorizationDocuments.length) {
      setFormError({ ...newFormError, newAuthorizationID: 'Please enter or upload authorization documents.' });
      return;
    }

    if (Object.keys(newFormError).length) {
      setFormError(newFormError);
      return;
    }
    setIsLoading(true);
    const docsList = await uploadFiles(authorizationDocuments);
    completePeerToPeerTask({
      ...formData,
      authorizationDocuments: docsList as (FileTypeWithTitle & FileView)[],
      newReviewResult: newReviewResult === -1 ? null : newReviewResult,
    })
      .then(() => {
        if (!signal.aborted) {
          getAllTaskInfo(true);
        }
      })
      .catch(e => {
        if (!signal.aborted) {
          setModalError({
            title: 'Complete error',
            content: e.message,
          });
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const onCloseErrorModal = (): void => {
    setModalError({
      title: '',
      content: '',
    });
  };

  const refreshTaskDetails = () => {
    getAllTaskInfo(false);
  };

  return (
    <>
      <div className='task-content_form'>
        {!!checkForEmptyString(task.TaskInstruction) && (
          <>
            <div className='title'>Task Instructions:</div>
            <div className='text'>{task.TaskInstruction}</div>
          </>
        )}
        <Form
          className='task-form_reviewer'
          formValue={formData}
          model={PeerReviewTaskSchema}
          ref={formRef}
          onCheck={formError => setFormError(formError)}
          onChange={data => setFormData(data as PeerReviewTaskFormData)}
        >
          {TaskStatuses.Open === task.TaskStatus ? (
            <div className='content'>
              <div className='row'>
                <Form.Group className='form-new-scan-group'>
                  <TextField
                    name='newReviewerName'
                    appearance='subtle'
                    label='Reviewer name *:'
                    value={formData.newReviewerName}
                    labelClassName='task-label'
                    error={formError.newReviewerName}
                  />
                </Form.Group>
                <Form.Group className='form-new-scan-group'>
                  <TextField
                    name='newReviewerSpecialty'
                    appearance='subtle'
                    label='Reviewer specialty:'
                    value={formData.newReviewerSpecialty}
                    labelClassName='task-label'
                    error={formError.newReviewerSpecialty}
                  />
                </Form.Group>
              </div>
              <Form.Group className={`col-12 ${formError.newReviewResult ? 'error' : ''}`}>
                <Form.ControlLabel className='title'>Review status *:</Form.ControlLabel>
                <Form.Control
                  name='newReviewResult'
                  errorPlacement='bottomStart'
                  accepter={RadioGroup}
                  value={formData.newReviewResult as number}
                  errorMessage=''
                  inline
                >
                  <Radio value={1}>Approved</Radio>
                  <Radio value={0}>Denied</Radio>
                </Form.Control>
                <Form.ErrorMessage className='error-text' show={!!formError.newReviewResult} placement={'bottomStart'}>
                  {formError.newReviewResult}
                </Form.ErrorMessage>
              </Form.Group>
              {formData.newReviewResult === 1 && (
                <>
                  <Form.Group className='form-new-scan-group flex-1 mx-0'>
                    <TextField
                      name='newAuthorizationID'
                      appearance='subtle'
                      label='Approval ID *:'
                      labelClassName='task-label'
                      error={formError.newAuthorizationID}
                      value={formData.newAuthorizationID}
                    />
                  </Form.Group>
                  <div className='form-new-scan-uploader'>
                    <span className='form-new-scan-divider'>---OR---</span>
                    <FilesUploader
                      fileList={formData.authorizationDocuments}
                      uploadFiles={uploadDocuments}
                      title='Uploaded authorization documents:'
                      updateFiles={(files: Array<FileTypeWithTitle>) => {
                        setFormData({ ...formData, authorizationDocuments: files as Array<FileTypeWithTitle & FileView> });
                        setFormError({ ...formError, fileNoteName: '' });
                      }}
                      downloadFileFn={downloadFile}
                      checkFile={checkDocuments}
                      fileLoadFinish={fileLoadFinish}
                    />
                    {!!formError.fileNoteName && <div className='error-text-new-scan'>{formError.fileNoteName}</div>}
                  </div>
                </>
              )}
              {formData.newReviewResult === 0 && (
                <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                  <TextField
                    name='newDenialReason'
                    accepter={Textarea}
                    appearance='subtle'
                    error={formError.newDenialReason}
                    label='Denial Reason:'
                    labelClassName='task-label'
                    value={formData.newDenialReason}
                  />
                </Form.Group>
              )}
              <Form.Group className='form-new-scan-group flex-1 mx-0 form-new-task-textarea'>
                <TextField
                  name='newTaskNotes'
                  accepter={Textarea}
                  appearance='subtle'
                  label='Task notes:'
                  labelClassName='task-label'
                  value={formData.newTaskNotes}
                />
              </Form.Group>
              <Button className='rs-btn-model btn-lg' onClick={submit} disabled={isLoading || !isSubmitEnabled}>
                Mark as Completed
              </Button>
            </div>
          ) : (
            <StaticP2PTaskComponent fileList={authorizationFiles} data={special as P2PtaskResponse} taskNotes={task.TaskNotes as string} />
          )}
        </Form>
      </div>
      <div className='task-details'>
        <div className='task-details_tabs'>
          <div className='tabs-panel'>
            <Nav appearance='tabs' activeKey={activeNav} onSelect={setActiveNav}>
              <Nav.Item eventKey={TASK_TABS_NAME.Details}>Details</Nav.Item>
              <Nav.Item eventKey={TASK_TABS_NAME.ClinicalNotes}>Clinical notes</Nav.Item>
            </Nav>
          </div>
          <div className='task-details_tabs_content'>
            {activeNav === TASK_TABS_NAME.Details && (
              <DetailsTaskTabComponent data={{ special, task, testRequest, insurance, Patient }} taskDetailsChanged={refreshTaskDetails} />
            )}
            {activeNav === TASK_TABS_NAME.ClinicalNotes && (
              <ClinicalNotesTaskTabComponent noteFiles={taskData.noteFiles} ClinicalNotes={taskData.testRequest?.ClinicalNotes} />
            )}
          </div>
        </div>
      </div>
      {modalError.content && <InfoModalComponent type={`error`} texts={modalError} onClose={onCloseErrorModal} />}
    </>
  );
};

export default PeerReviewTaskComponent;

import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import AccountsImg from '../../assets/accounts-placeholder.png';
import LoaderComponent from '../../components/loader-component';
import ErrorMessageComponent from '../../components/error-message-component';
import { DashboardUrl } from '../../config/UrlsConfig';
import { ChildAccount } from './interfaces';
import { switchAccount, getChildAccounts, createUserSessionLog } from '../../api/users/UsersAPI';
import { convertToLocalDate, getUtcOffset } from '../../utils/TimeUtil';
import { UserContext, UserContextType } from '../../contexts/UserContext';

const ChooseAccountComponent: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [childAccounts, setChildAccounts] = useState<ChildAccount[]>([]);
  const [errorText, setErrorText] = useState<string | null>(null);
  const history = useHistory();
  const currentUser = useContext<UserContextType>(UserContext);

  useEffect(() => {
    if (!currentUser?.IsMemberOfParentAccount) {
      history.push(DashboardUrl);
      return;
    }

    setErrorText(null);
    setIsLoading(true);

    getChildAccounts(currentUser.ID)
      .then(res => {
        setChildAccounts(res);
      })
      .catch(() => {
        setErrorText('texts.recentActivityErrorText');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const createSessionLog = () => {
    const sessionStart = convertToLocalDate(new Date());
    const utcOffset = getUtcOffset();
    createUserSessionLog(sessionStart, utcOffset);
  };

  const selectChildAccount = (account: ChildAccount) => {
    setErrorText(null);
    setIsLoading(true);

    switchAccount(account.POID)
      .then(() => {
        createSessionLog();
        return currentUser.updateUser();
      })
      .then(() => history.push(DashboardUrl))
      .catch(error => {
        console.error(error);
        setErrorText('There was an error selecting the account');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='page-content choose-account'>
      {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      {isLoading && <LoaderComponent />}

      {!isLoading && <h1 className='title'>Choose your account</h1>}

      {!isLoading && (
        <div className='account-card-container'>
          {childAccounts.map(el => (
            <div className='account-card' key={el.POID} onClick={() => selectChildAccount(el)}>
              <img src={AccountsImg} alt='img' width='48' height='48' />
              <span className='account-card__name'>{el.POName}</span>
              <span className='account-card__chevron'></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChooseAccountComponent;

import {
  AllScansUrl,
  DashboardUrl,
  NewScan,
  LoginUrl,
  PatientsUrl,
  SettingsUrl,
  UiKitUrl,
  PatientInfoUrl,
  ScanUrl,
  OnBoardingUrl,
  OnBoardingAdminUrl,
  ForgotPasswordUrl,
  ResetPassword,
  PatientScanDetailUrl,
  PrivacyUrl,
  TermsUrl,
  BaaUrl,
  NotFoundUrl,
  HomeUrl,
  InvalidUrl,
  TasksUrl,
  TaskUrl,
  AllCommentsUrl,
  ChooseAccountUrl,
} from '../config/UrlsConfig';
import LoginComponent from '../views/login';
import DashboardComponent from '../views/dashboard';
import AllScansComponent from '../views/all-scans';
import ScanInfoComponent from '../views/all-scans/scan';
import createNewScan from '../views/new-scan';
import PatientsComponent from '../views/patients';
import PatientInfoComponent from '../views/patients/patient';
import SettingsComponent from '../views/portal-settings';
import UiKitComponent from '../views/ui-kit';
import OnBoardingComponent from '../views/onboarding';
import ForgotPasswordComponent from '../views/forgot-password';
import TermsComponent from '../views/terms';
import BaaComponent from '../views/baa';
import PrivacyComponent from '../views/privacy';
import NotFoundComponent from '../views/not-found';
import InValidPageComponent from '../views/invalid';
import { AccountStatuses } from '../models/User';
import TasksComponent from '../views/tasks';
import TaskComponent from '../views/tasks/task';
import AllCommentsComponent from '../views/comments';
import ChooseAccountComponent from '../views/choose-account';

import { CHARGBEE_ACTIVE } from '../config/FrontConfig';

const defaultAdminPredicate = () => true;
const unauthorizedAdminPredicate = isAdminPredicate => !isAdminPredicate;
const authorizedPredicate = isAuthenticated => isAuthenticated;
const trialIsActive = (isAuthenticated, IsChargebeeUsed, accountStatus) =>
  isAuthenticated && !(IsChargebeeUsed && CHARGBEE_ACTIVE && accountStatus === AccountStatuses.Expired);
const unauthorizedPredicate = isAuthenticated => !isAuthenticated;

const authorizedRoutePart = [
  {
    path: HomeUrl,
    exact: true,
    wrapper: null,
    component: DashboardComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: DashboardUrl,
    exact: true,
    wrapper: null,
    component: DashboardComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: NewScan,
    exact: true,
    wrapper: null,
    component: createNewScan,
    isActive: trialIsActive,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: AllScansUrl,
    exact: true,
    wrapper: null,
    component: AllScansComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: ScanUrl,
    exact: true,
    wrapper: null,
    component: ScanInfoComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: PatientsUrl,
    exact: true,
    wrapper: null,
    component: PatientsComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: PatientInfoUrl,
    exact: true,
    wrapper: null,
    component: PatientInfoComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: PatientScanDetailUrl,
    exact: true,
    wrapper: null,
    component: ScanInfoComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: SettingsUrl,
    exact: true,
    wrapper: null,
    component: SettingsComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: TasksUrl,
    exact: true,
    wrapper: null,
    component: TasksComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: TaskUrl,
    exact: true,
    wrapper: null,
    component: TaskComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: NotFoundUrl,
    exact: true,
    wrapper: null,
    component: NotFoundComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: InvalidUrl,
    exact: true,
    wrapper: null,
    component: InValidPageComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: AllCommentsUrl,
    exact: true,
    wrapper: null,
    component: AllCommentsComponent,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
  {
    path: ChooseAccountUrl,
    exact: true,
    wrapper: null,
    component: ChooseAccountComponent,
    showSidebar: false,
    showLoginDropdown: false,
    isActive: authorizedPredicate,
    isAdminPageActive: unauthorizedAdminPredicate,
  },
];

const unauthorizedRoutePart = [
  {
    path: LoginUrl,
    exact: true,
    wrapper: null,
    component: LoginComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: OnBoardingUrl,
    exact: true,
    wrapper: null,
    component: OnBoardingComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: OnBoardingAdminUrl,
    exact: true,
    wrapper: null,
    component: OnBoardingComponent,
    isActive: unauthorizedPredicate,
  },
  {
    path: ForgotPasswordUrl,
    exact: true,
    wrapper: null,
    component: ForgotPasswordComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: ResetPassword,
    exact: true,
    wrapper: null,
    component: ForgotPasswordComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: PrivacyUrl,
    exact: true,
    wrapper: null,
    component: PrivacyComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: TermsUrl,
    exact: true,
    wrapper: null,
    component: TermsComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: BaaUrl,
    exact: true,
    wrapper: null,
    component: BaaComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
  {
    path: UiKitUrl,
    exact: true,
    wrapper: null,
    component: UiKitComponent,
    isActive: unauthorizedPredicate,
    isAdminPageActive: defaultAdminPredicate,
  },
];

export const RouteConfig = [...authorizedRoutePart, ...unauthorizedRoutePart];

import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import { ChooseAccountUrl } from '../../config/UrlsConfig';
import { UserContextType, UserContext } from '../../contexts/UserContext';
import { LoginDropdownProps } from './interfaces';

const LoginDropdownComponent: React.FunctionComponent<LoginDropdownProps> = props => {
  const { logout } = props;
  const currentUser = useContext<UserContextType>(UserContext);
  const history = useHistory();

  const nameUser = useMemo(() => {
    if (currentUser && currentUser.FirstName) {
      const name = currentUser.FirstName;
      if (currentUser.LastName) {
        const lastName = currentUser.LastName;
        return name.substring(0, 1) + lastName.substring(0, 1);
      }
      return name.substring(0, 1);
    }

    return '';
  }, [currentUser]);

  const showSwitchAccount = useMemo(() => {
    return Boolean(currentUser?.IsMemberOfParentAccount);
  }, [currentUser]);

  return (
    <Dropdown className='profile-icon' title={nameUser}>
      {showSwitchAccount && (
        <Dropdown.Item
          onClick={() => {
            history.push(ChooseAccountUrl);
          }}
        >
          <span className='icon-switch-acc'></span>Switch account
        </Dropdown.Item>
      )}

      <Dropdown.Item
        onClick={() => {
          localStorage.removeItem('isAuth');
          logout();
        }}
      >
        <span className='icon-logout'></span>
        Logout
      </Dropdown.Item>
    </Dropdown>
  );
};
export default LoginDropdownComponent;

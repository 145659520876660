import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'rsuite';
import TextField from '../../../../components/text-field-component';
import GeneralEditModal from './GeneralModal';
import { NotificationData, Notifications } from '../../../../models/User';
import { sendNotifications } from '../../../../api/general/GeneralAPI';
import { DefaultFormValue, notificationUpdatedText, ConfirmModalCancelText } from './GeneralUtil';
import { useError } from '../../../../utils/UseError';
import { UserContextType, UserContext, EMPTY_NOTIFICATIONS, INIT_CHECKBOXES_STATE } from '../../../../contexts/UserContext';
import PasswordModal from '../../components/PasswordModal';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { UserInfo } from './interfaces';
import { AppProps } from '../../../../components/router-component/interfaces';
import { Prompt } from 'react-router';
import ToogleButton from './ToggleButton';
import { getLocations } from '../../../../api/locations/LocationsAPI';
import { SelectOption } from '../../../../models/General';
import CheckboxFieldsBlock from './CheckboxFieldsBlock';
import ErrorMessageComponent from '../../../../components/error-message-component';
import { useSignal } from '../../../../utils/UseSignal';

const SettingsGeneralTabComponent: React.FunctionComponent<AppProps> = () => {
  const [user, setUser] = useState<UserInfo | null>(null);
  const [formValue, setFormValue] = useState<Notifications>(EMPTY_NOTIFICATIONS);
  const [showEditGeneralModal, setShowEditGeneralModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [errorText, setErrorText] = useError(null);
  const [infoModalText, setInfoModalText] = useState<{ title: string; content: string } | null>(null);
  const [locations, setLocations] = useState<SelectOption[]>([]);
  const signal = useSignal();

  const currentUser = useContext<UserContextType>(UserContext);

  const allValues: number[] = useMemo(() => Object.values(locations).map(item => item.value), [locations]);

  const isAnyChanges = useMemo(() => {
    if (!currentUser.Notifications) {
      return false;
    }

    return Object.entries(formValue).some(([field, obj]) => {
      return Object.entries(obj).some(
        ([key, value]) => value !== currentUser.Notifications[field as keyof Notifications]?.[key as keyof NotificationData],
      );
    });
  }, [formValue, currentUser.Notifications]);

  const showNotificationSettings = useMemo(() => {
    return !currentUser?.IsMemberOfParentAccount;
  }, [currentUser]);

  const getAllLocations = () => {
    getLocations(signal)
      .then(res => {
        const filledArray: SelectOption[] = [];

        Object.values(res).map(el => {
          filledArray.push({
            label: el.Address1 + ' ' + el.City,
            value: el.ID as number,
          });
        });

        setLocations(filledArray);
      })
      .catch(() => {
        if (!signal.aborted) {
          setLocations([]);
        }
      });
  };

  useEffect(() => {
    setUser({
      Email: currentUser.Email,
      FirstName: currentUser.FirstName,
      LastName: currentUser.LastName,
    });

    setFormValue(currentUser?.Notifications || EMPTY_NOTIFICATIONS);
  }, [currentUser.FirstName, currentUser.LastName, currentUser.Notifications]);

  useEffect(() => {
    getAllLocations();
  }, []);

  const onChangeToogle = (value: boolean, name: keyof Notifications) => {
    const status = value ? 1 : 0;
    setFormValue(prev => ({
      ...prev,
      [name]: { ...INIT_CHECKBOXES_STATE, Self: status, Owner: status },
    }));
  };

  const onCloseInfoModal = (): void => {
    setInfoModalText(null);
  };

  const handleSubmit = () => {
    sendNotifications(formValue)
      .then(res => {
        if (!res.success) {
          setErrorText(`Notifications doesn't save`);
        }
        currentUser.expandUser({ Notifications: formValue });
        setInfoModalText(notificationUpdatedText);
      })
      .catch(err => setErrorText(err));
  };

  const handleCloseEditModal = (isSubmitPressed: boolean): void => {
    setShowEditGeneralModal(isSubmitPressed);
  };

  return (
    <div className='g-setting-panel'>
      <div className='g-setting-panel_title'>Profile Information</div>
      <button className='btn-edit big' onClick={() => setShowEditGeneralModal(true)}>
        <span className='icon-edit'></span>
        Edit profile
      </button>
      <Form formValue={user ? user : DefaultFormValue} className='form-profile-info form-profile-info-main row'>
        <Prompt when={isAnyChanges} message={JSON.stringify(ConfirmModalCancelText)} />
        <TextField name='FirstName' value={user?.FirstName} label='First name' disabled />
        <TextField name='LastName' value={user?.LastName} label='Last name' disabled />
        <TextField name='Email' value={user?.Email} label='Email address' disabled />
        <div className='setting-password'>
          <div className='g-setting-panel_title'>Password</div>
          <button className='btn-edit big' onClick={() => setShowPasswordModal(true)}>
            <span className='icon-edit'></span>
            Change Password
          </button>
        </div>
      </Form>
      {showNotificationSettings && <div className='g-setting-panel_title'>Email Notification Settings</div>}
      {showNotificationSettings && (
        <div className='checkbox-setting-nav row'>
          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.NEWSCANREQUEST}
                label='New Scan Request Notifications'
                tooltipText='Turn on email notifications to receive updates when a scan request is created.'
                handleChange={value => onChangeToogle(value, 'NEWSCANREQUEST')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                data={formValue.NEWSCANREQUEST}
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    NEWSCANREQUEST: { ...prev.NEWSCANREQUEST, ...data },
                  }))
                }
              />
            </div>
          </div>
          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.CANCEL}
                label='Cancel Notification'
                tooltipText='Turn on email notifications to receive updates when a scan request is cancelled.'
                handleChange={value => onChangeToogle(value, 'CANCEL')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                data={formValue.CANCEL}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    CANCEL: { ...prev.CANCEL, ...data },
                  }))
                }
              />
            </div>
          </div>

          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.BOOKING}
                label='Appointment Scheduled Notifications'
                tooltipText='Turn on email notifications to receive updates when an appointment has been scheduled and confirmed.'
                handleChange={value => onChangeToogle(value, 'BOOKING')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                data={formValue.BOOKING}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    BOOKING: { ...prev.BOOKING, ...data },
                  }))
                }
              />
            </div>
          </div>
          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.MFPCOMMENT}
                label='Internal Comment Notifications'
                tooltipText='Turn on email notifications to receive updates when a colleague leaves a comment.'
                handleChange={value => onChangeToogle(value, 'MFPCOMMENT')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                data={formValue.MFPCOMMENT}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    MFPCOMMENT: { ...prev.MFPCOMMENT, ...data },
                  }))
                }
              />
            </div>
          </div>

          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.REPORT}
                label='New Report Notifications'
                tooltipText='Turn on email notifications to receive updates when a report is uploaded for a scan request.'
                handleChange={value => onChangeToogle(value, 'REPORT')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                data={formValue.REPORT}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    REPORT: { ...prev.REPORT, ...data },
                  }))
                }
              />
            </div>
          </div>
          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.CXCOMMENT}
                label='Medmo Care Team Comment Notifications'
                tooltipText='Turn on email notifications to receive updates when Medmo&#39;s Care Team leaves a comment.'
                handleChange={value => onChangeToogle(value, 'CXCOMMENT')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                allLocationsValues={allValues}
                data={formValue.CXCOMMENT}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    CXCOMMENT: { ...prev.CXCOMMENT, ...data },
                  }))
                }
              />
            </div>
          </div>
          <div className='checkbox-setting-nav_item checkbox-row'>
            <div className='row'>
              <ToogleButton
                data={formValue.TASK}
                label='Task notifications'
                tooltipText='Turn on email notifications to receive updates when a task is created or assigned.'
                handleChange={value => onChangeToogle(value, 'TASK')}
              />
            </div>
            <div className='checkbox-setting_list_wrap'>
              <CheckboxFieldsBlock
                locations={locations}
                checkboxText={'Only the requests and/or tasks where I am the owner'}
                allLocationsValues={allValues}
                data={formValue.TASK}
                checkboxTooltip={
                  'Turn on setting to only receive notification of your scans (You can view the owners of the scans in the All Scans and Tasks page)'
                }
                locationTooltip={
                  'Turn on setting to only receive notification base on the locations of your choice (you can view locations of the scans in the All Scans and Task page)'
                }
                setData={data =>
                  setFormValue(prev => ({
                    ...prev,
                    TASK: { ...prev.TASK, ...data },
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}
      {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      <div className='btn-row-setting justify-content-end'>
        <Button classPrefix='btn-model btn-close btn-lg' disabled={!isAnyChanges} onClick={() => setFormValue(currentUser.Notifications)}>
          Cancel
        </Button>
        <Button classPrefix='btn-model btn-lg' disabled={!isAnyChanges} onClick={handleSubmit}>
          Save
        </Button>
      </div>

      {showEditGeneralModal && (
        <GeneralEditModal
          general={user ? user : DefaultFormValue}
          setInfoModalText={value => setInfoModalText(value)}
          onClose={handleCloseEditModal}
        />
      )}
      {showPasswordModal && (
        <PasswordModal setInfoModalText={value => setInfoModalText(value)} onClose={() => setShowPasswordModal(false)} />
      )}
      {infoModalText && (
        <InfoModalComponent onClose={onCloseInfoModal} type={'success'} texts={infoModalText as { title: string; content: string }} />
      )}
    </div>
  );
};

export default SettingsGeneralTabComponent;

import React, { useCallback, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { DashboardUrl, LoginUrl, SettingsUrl } from '../../config/UrlsConfig';
import { UserContextType, UserContext } from '../../contexts/UserContext';
import UserConfirmation from '../../utils/UseConfirmation';
import PageNotFound from '../../views/not-found';
import DefaultAuthorizedWrapper from '../default-authorized-wrapper';
import { Props, RoutesItem } from './interfaces';

const RouterComponent: React.FunctionComponent<Props> = props => {
  const { isAuthenticated, items, isAdmin, isReload } = props;
  const currentUser = useContext<UserContextType>(UserContext);

  const itemsCallback = useCallback(() => {
    const filteredAuthItems: RoutesItem[] = [];
    const filteredForbiddenItems: RoutesItem[] = [];

    items.forEach(item => {
      if (
        item.isActive(isAuthenticated, currentUser?.PO?.IsChargebeeUsed, currentUser?.PO?.Access?.AccountStatus) &&
        typeof item.isAdminPageActive === 'function' &&
        item.isAdminPageActive(isAdmin)
      ) {
        filteredAuthItems.push(item);
      } else {
        filteredForbiddenItems.push(item);
      }
    });

    return { filteredAuthItems, filteredForbiddenItems };
  }, [isAuthenticated, items, isAdmin]);

  const routesAuth = itemsCallback().filteredAuthItems.map(item => (
    <Route
      key={item.path}
      exact={item.exact}
      path={item.path}
      component={(properties: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
        <DefaultAuthorizedWrapper
          key={item.path}
          showSidebar={Object.hasOwn(item, 'showSidebar') ? item.showSidebar : true}
          showLoginDropdown={Object.hasOwn(item, 'showLoginDropdown') ? item.showLoginDropdown : true}
          {...props}
          leave={props.logOut}
        >
          {item.wrapper ? (
            <item.wrapper>
              <item.component {...props} {...properties} />
            </item.wrapper>
          ) : (
            <item.component {...props} {...properties} />
          )}
        </DefaultAuthorizedWrapper>
      )}
    />
  ));

  const routesNotAuth = items
    .filter(item => item.isActive(isAuthenticated))
    .map(item => (
      <Route
        key={item.path}
        exact={item.exact}
        path={item.path}
        component={(properties: JSX.IntrinsicAttributes) =>
          item.wrapper ? (
            <item.wrapper key={item.path}>
              <item.component {...props} {...properties} />
            </item.wrapper>
          ) : (
            <item.component key={item.path} {...props} {...properties} />
          )
        }
      />
    ));

  const renderAuthRoutes = useCallback(() => {
    let route;

    if (isAdmin) {
      route = <Redirect key='*' to={SettingsUrl} />;
    } else {
      const isForbidden = itemsCallback().filteredForbiddenItems.some(el => el.path === window.location.pathname);

      route = isForbidden ? (
        <Redirect key='*' to={DashboardUrl} />
      ) : (
        <Route
          path='*'
          key='*'
          component={() => (
            <DefaultAuthorizedWrapper {...props} leave={props.logOut}>
              <PageNotFound />
            </DefaultAuthorizedWrapper>
          )}
        />
      );
    }

    return [...routesAuth, route];
  }, [isAuthenticated, items, isAdmin, isReload]);

  const renderNotAuthRoutes = () => {
    return routesNotAuth;
  };

  return (
    <BrowserRouter getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}>
      <Switch>
        <Route
          exact
          path='/'
          render={() => {
            return isAuthenticated ? isAdmin ? <Redirect to={SettingsUrl} /> : <Redirect to={DashboardUrl} /> : <Redirect to={LoginUrl} />;
          }}
        />
        {isAuthenticated ? renderAuthRoutes() : renderNotAuthRoutes()}
        <Redirect to={LoginUrl} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;

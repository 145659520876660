import { FilterDataType } from '../all-scans/filter-scans-modal/interfaces';

export const columns = [
  {
    dataKey: 'PatientName',
    align: 'center',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 1.2,
    minWidth: 225,
    sortable: false,
  },
  {
    dataKey: 'DOB',
    align: 'left',
    headTitle: 'DOB',
    tooltip: 'DOB',
    width: 140,
    // flexGrow: 0.6,
    sortable: false,
  },
  {
    dataKey: 'Modality',
    align: 'center',
    headTitle: 'Modality',
    tooltip: 'Type of modality',
    flexGrow: 0.7,
    sortable: true,
    minWidth: 160,
    type: 'scanTypeToolTip',
    fieldName: 'FriendlyName',
  },
  {
    dataKey: 'TestRequestID',
    align: 'left',
    headTitle: 'SRID',
    tooltip: 'Scan request ID',
    sortable: true,
    width: 115,
    type: 'link',
    linkUrl: '/all-scans',
    usePath: false,
  },
  {
    dataKey: 'CreatorName',
    align: 'left',
    minWidth: 180,
    headTitle: 'Enter By',
    flexGrow: 1,
    sortable: true,
  },
  {
    dataKey: 'Created',
    align: 'center',
    headTitle: 'Created',
    tooltip: 'Comment created date',
    // flexGrow: 0.8,
    width: 215,
    sortable: true,
  },
  {
    dataKey: 'NoteText',
    align: 'left',
    headTitle: 'Message',
    tooltip: 'Message',
    width: 600,
    type: 'modal',
    idKey: 'TestRequestID',
    sortable: false,
  },
];

export const ConfirmModalTexts = {
  removeNote: {
    title: `Are you sure you want to delete this comment?`,
    text: `You will not be able to undo this action.`,
    cancelBtnText: 'Cancel',
    submitBtnText: 'Yes, Delete It',
  },
};

export const searchBarColumns = [
  {
    dataKey: 'PatientName',
    //width: 198,
    align: 'left',
    headTitle: 'Patient',
    tooltip: 'Patient name',
    flexGrow: 2,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'DOB',
    //width: 198,
    align: 'center',
    headTitle: 'DOB',
    tooltip: 'Patient date of birth',
    flexGrow: 0.8,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'TestRequestID',
    //width: 198,
    align: 'left',
    headTitle: 'SRID',
    tooltip: 'Scan request ID',
    flexGrow: 0.5,
    sortable: false,
    type: 'withBoldText',
  },
  {
    dataKey: 'NoteText',
    align: 'left',
    headTitle: 'Message',
    tooltip: 'Message',
    width: 500,
    idKey: 'TestRequestID',
    sortable: true,
    fixed: true,
    type: 'withBoldText',
  },
];

export const TABS_NAME = {
  Comment: 'comments',
  Report: 'reports',
  Details: 'request_details',
  Scheduling: 'scheduling_information',
  Tasks: 'tasks',
};

export const initDataForFilter: FilterDataType = {
  from: null,
  to: null,
};

import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import TextField from '../text-field-component';
import { Button, ButtonToolbar, Modal, Form, SelectPicker } from 'rsuite';
import { PatientFormValue, PatientSchema } from '../../views/patients/PatientsUtil';
import { FormInstance } from 'rsuite/Form';
import { createPatient, editPatient } from '../../api/patient/PatientAPI';
import InputMask from '../mask-field';
import { getState } from '../../views/portal-settings/tabs/locations/LocationState';
import { Patient } from '../../models/Patient';
import { transformDate } from '../../utils/TimeUtil';
import { PatientModal } from '../../views/patients/interfaces';
import { FormError } from '../../models/General';
import RadioGroupField from '../radio-field-component';
import { useError } from '../../utils/UseError';
import { CommonContext, CommonContextType } from '../../contexts/CommonContext';
import { useSignal } from '../../utils/UseSignal';

const AddPatientSchema = PatientSchema;

const AddPatientModalComponent: React.FunctionComponent<PatientModal> = props => {
  const { onClose, patientData, isEdit, onCheckFields } = props;
  const [formValue, setFormValue] = useState<Patient>(PatientFormValue);
  const [formError, setFormError] = useState<FormError>({});
  const [errorText, setError, dataErrors, setDataErrors] = useError('');
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const form = useRef<FormInstance | null>(null);
  const fieldsForCheck: string[] = ['FirstName', 'LastName', 'Phone', 'GenderId', 'DOB', 'MRN', 'State', 'ZipCode'];
  const { Languages, Genders } = useContext<CommonContextType>(CommonContext);
  const signal = useSignal();

  useEffect(() => {
    if (!patientData) {
      return;
    }
    const data = { ...patientData };
    const NewPatientFormValue: Patient & { [key: string]: string | boolean | number | Date | null | undefined } = { ...PatientFormValue };

    Object.keys(PatientFormValue).forEach(key => {
      if (key === 'DOB' && data.DOB) {
        NewPatientFormValue[key] = new Date(data[key] as string);
      }

      if (key === 'GenderId') {
        const element = Genders.find((el: { label: string }) => el.label === data['Gender']);
        if (element) {
          NewPatientFormValue[key] = element.value;
        }
      } else {
        NewPatientFormValue[key] = data[key as keyof Patient];
      }
    });
    setFormValue(NewPatientFormValue);
  }, [patientData]);

  const handleSubmit = () => {
    if (form.current && !form.current.check?.()) {
      return;
    }

    setIsSubmitPressed(true);

    const data = { ...formValue };
    if (!data.Email || data.Email.length === 0) {
      data.Email = null;
    }
    if (data.DOB && data.DOB !== '') {
      data.DOB = moment(data.DOB).format('YYYY-MM-DD');
    }

    if (isEdit) {
      if (patientData && patientData.ID) {
        editPatient(patientData.ID, data)
          .then(() => onClose(true))
          .catch(err => setError(err, formValue))
          .finally(() => {
            if (!signal.aborted) {
              setIsSubmitPressed(false);
            }
          });
      }
    } else {
      createPatient(data)
        .then(res => onClose(true, res.ID))
        .catch(err => setError(err, formValue))
        .finally(() => {
          if (!signal.aborted) {
            setIsSubmitPressed(false);
          }
        });
    }
  };

  const handleChange = (formValue: Patient, event: ChangeEvent<HTMLInputElement>) => {
    errorText && setError(null);
    const fieldName = event?.target?.name;

    if (fieldName && dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const handleClickCancel = () => {
    if (isEdit && !isSubmitPressed) {
      onClose(false);
      return;
    }

    if (form.current && onCheckFields && !isSubmitPressed) {
      if (onCheckFields(formValue, fieldsForCheck)) {
        onClose(false);
      }
      return;
    }

    if (!isSubmitPressed) {
      onClose(false);
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (isEdit && patientData) {
      return (Object.keys(formValue) as [keyof Patient]).every(key => {
        if (key === 'GenderId') {
          const element = Genders.find((el: { label: string }) => el.label === patientData['Gender']);

          if (element) {
            return formValue.GenderId === element.value;
          }
        }

        if (key in patientData) {
          if (!formValue[key]) {
            return !patientData[key];
          }
          return formValue[key] === patientData[key];
        }

        return true;
      });
    }

    return false;
  }, [formValue]);

  return (
    <div>
      <Modal size={'sm'} className='patient-model' overflow={false} backdropClassName='patient-model_mask' open onClose={handleClickCancel}>
        <Modal.Header>
          <Modal.Title>{isEdit ? 'Edit Patient' : 'Add New Patient'}</Modal.Title>
          <button className='btn-close' onClick={handleClickCancel}>
            <i className='rs-icon rs-icon-close'></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={form}
            onChange={(formValue, event) => handleChange(formValue as Patient, event as ChangeEvent<HTMLInputElement>)}
            onCheck={formError => setFormError(formError)}
            formValue={formValue}
            model={AddPatientSchema}
          >
            <div className='row'>
              <TextField
                name='FirstName'
                autoComplete='off'
                error={formError.FirstName || dataErrors.FirstName}
                value={formValue.FirstName}
                label='Patient first name *:'
              />
              <TextField
                name='LastName'
                autoComplete='off'
                error={formError.LastName || dataErrors.LastName}
                value={formValue.LastName}
                label='Patient last name *:'
              />
            </div>
            <div className='row'>
              <TextField
                accepter={InputMask}
                className='birthdate mb2'
                name='DOB'
                autoComplete='off'
                error={formError.DOB || dataErrors.DOB}
                value={formValue.DOB && transformDate(formValue.DOB)}
                mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                format='MM/DD/YYYY'
                label='Date of birth *:'
              />
              <TextField
                name='MRN'
                className='mri mb2'
                autoComplete='off'
                error={formError.MRN || dataErrors.MRN}
                value={formValue.MRN}
                label='MRN ID:'
              />
            </div>
            <RadioGroupField
              data={Genders}
              value={formValue.GenderId || dataErrors.GenderId}
              name='GenderId'
              label='Sex *'
              error={formError.GenderId}
            />
            <div className='row'>
              <TextField
                accepter={SelectPicker}
                name='PreferredLanguageID'
                data={Languages}
                className='six'
                error={formError.PreferredLanguageID || dataErrors.PreferredLanguageID}
                value={formValue.PreferredLanguageID}
                appearance='subtle'
                placeholder=' '
                label='Preferred language:'
                searchable={false}
              />
            </div>
            <div className='row'>
              <TextField
                name='Email'
                autoComplete='off'
                error={formError.Email || dataErrors.Email}
                value={formValue.Email}
                label='Email address:'
              />
              <TextField
                accepter={InputMask}
                error={formError.Phone || dataErrors.Phone}
                value={formValue.Phone}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                name='Phone'
                autoComplete='off'
                label='Phone number *:'
              />
            </div>
            <div className='row'>
              <TextField
                className='zip-code'
                error={formError.ZipCode || dataErrors.ZipCode}
                value={formValue.ZipCode}
                name='ZipCode'
                autoComplete='off'
                label='ZIP code:'
                accepter={InputMask}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
              />
              <TextField
                accepter={SelectPicker}
                className='state'
                name='State'
                data={getState}
                error={formError.State || dataErrors.State}
                value={formValue.State}
                appearance='subtle'
                placeholder='Select state'
                searchable={true}
                placement='topStart'
                label='State:'
                menuMaxHeight={287}
              />
            </div>
          </Form>
        </Modal.Body>
        {errorText && <p className='block-error-text'>{errorText}</p>}
        <Modal.Footer>
          <ButtonToolbar>
            <Button classPrefix='btn-model btn-close btn-lg' className='btn-lg' disabled={isSubmitPressed} onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button classPrefix='btn-model' className='btn-lg' disabled={isSubmitPressed || isSubmitDisabled} onClick={handleSubmit}>
              {isEdit ? 'Update Patient' : 'Add New Patient'}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPatientModalComponent;

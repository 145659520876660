import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'rsuite';
import TextFieldPassword from '../../../../components/text-field-password';
import { ConfirmModalAccountText, ExpiredTokenTextText, formOnboarder, NewPasswordFormValue } from './NewPasswordUtil';
import { FormInstance } from 'rsuite/Form';
import { useParams } from 'react-router';
import { checkToken, createNewPassword } from '../../../../api/login/LoginAPI';
import { useError } from '../../../../utils/UseError';
import LoaderComponent from '../../../../components/loader-component';
import InvalidForgotPasswordFormComponent from '../error';
import InfoModalComponent from '../../../../components/modals/info-modal';
import { useHistory } from 'react-router-dom';
import { NewPasswordFormData } from '../../interfaces';

const CreateNewPasswordFormComponent: React.FunctionComponent = () => {
  const [formValue, setFormValue] = useState<NewPasswordFormData>(NewPasswordFormValue);
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText, dataErrors, setDataErrors] = useError(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const form = useRef<FormInstance | null>(null);

  const history = useHistory();

  useEffect(() => {
    if (formValue.password) {
      form.current?.checkForField('confirmPassword');
    }
  }, [formValue.password]);

  useEffect(() => {
    if (formValue.confirmPassword) {
      form.current?.checkForField('password');
    }
  }, [formValue.confirmPassword]);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      checkToken(token)
        .then(data => {
          if (!data.success) {
            setErrorText(ExpiredTokenTextText);
          }
        })
        .catch(err => {
          setErrorText(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const handleSubmit = () => {
    if (!form.current || !form.current.check?.()) {
      return;
    }
    handleSubmitCreatePass(formValue, token);
  };

  const handleSubmitCreatePass = (data: NewPasswordFormData, token: string): void => {
    const { password, confirmPassword } = data;
    setIsLoading(true);

    if (password && confirmPassword) {
      createNewPassword(password, confirmPassword, token)
        .then(() => {
          setShowConfirm(true);
        })
        .catch(err => {
          setErrorText(err, formValue);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleChange = (formValue: NewPasswordFormData, event: ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;

    if (dataErrors[fieldName]) {
      setDataErrors({ ...dataErrors, [fieldName]: '' });
    }

    setFormValue(formValue);
  };

  const onCloseConfirmModal = (): void => {
    setShowConfirm(false);
    history.push(`/login`);
  };

  return (
    <>
      {errorText ? (
        <InvalidForgotPasswordFormComponent errorText={errorText} />
      ) : (
        <>
          {isLoading ? <LoaderComponent /> : null}
          {showConfirm && (
            <InfoModalComponent type='success' onClose={onCloseConfirmModal} texts={ConfirmModalAccountText.successAccount} />
          )}
          <div className='onboarder-wrap'>
            <div className='onboarder-title'>Create new password</div>
            <div className='onboarder-form forgot-form forgot-password-form'>
              <Form
                ref={form}
                onChange={(formValue, event) => handleChange(formValue as NewPasswordFormData, event as ChangeEvent<HTMLInputElement>)}
                formValue={formValue}
                model={formOnboarder}
              >
                <TextFieldPassword
                  name='password'
                  value={formValue.password}
                  error={dataErrors.password}
                  label='Create a password*'
                  rules='true'
                />
                <TextFieldPassword
                  name='confirmPassword'
                  value={formValue.confirmPassword}
                  error={dataErrors.confirmPassword}
                  label='Confirm password*'
                />
                <Button classPrefix='btn-model' onClick={handleSubmit}>
                  Create new password
                </Button>
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateNewPasswordFormComponent;

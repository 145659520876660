export const HomeUrl = '/';
export const LoginUrl = '/login';
export const DashboardUrl = '/dashboard';
export const AllScansUrl = '/all-scans';
export const NewScan = '/create-new-scan';
export const ScanUrl = '/all-scans/:scanId';
export const PatientsUrl = '/patients';
export const PatientInfoUrl = '/patients/:patientId';
export const PatientScanDetailUrl = '/patients/:patientId/scans/:scanId';
export const TasksUrl = '/tasks';
export const SettingsUrl = '/settings';
export const UiKitUrl = '/ui-kit';
export const OnBoardingUrl = '/on-boarding';
export const OnBoardingAdminUrl = '/on-boarding-admin';
export const ForgotPasswordUrl = '/forgot-password';
export const ResetPassword = '/reset/:token';
export const PrivacyUrl = '/privacy';
export const TermsUrl = '/terms/referrings';
export const BaaUrl = '/baa';
export const NotFoundUrl = '/not-found';
export const InvalidUrl = '/invalid';
export const TaskUrl = '/tasks/:taskId';
export const AllCommentsUrl = '/comments';
export const ChooseAccountUrl = '/choose-account';

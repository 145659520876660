import React, { useEffect, useState } from 'react';
import { Panel, Nav, Dropdown } from 'rsuite';
import ScanCommentsTabComponent from './tabs/comments';
import ScanReportsTabComponent from './tabs/reports';
import ScanDetailsTabComponent from './tabs/scan-details';
import ScanSchedulingTabComponent from './tabs/scheduling';
import { useParams } from 'react-router-dom';
import ScanRequestsStatus from '../../../components/scan-requests-status';
import { ExpandScanResult } from '../interfaces';
import { getScanInfo } from '../../../api/all-scans/AllScansAPI';
import ErrorMessageComponent from '../../../components/error-message-component';
import { useError } from '../../../utils/UseError';
import HeaderComponent from '../../../components/header-component';
import { updateScan } from '../../../utils/GeneralUtil';
import { useHistory, useLocation } from 'react-router';
import InvalidPageComponent from '../../invalid';
import { TABS_NAME } from '../AllScansUtil';
import { OwnerResponse } from '../../../models/Owner';
import { getOwners, setOwner as setNewOwner } from '../../../api/owner/OwnerAPI';
import ScanTasksTabComponent from './tabs/tasks';
import { ContentInvalidScan } from '../../invalid/invalidUtil';
import { useSignal } from '../../../utils/UseSignal';
import { AllScansUrl, PatientsUrl } from '../../../config/UrlsConfig';

const ScanInfoComponent: React.FunctionComponent = (): React.ReactElement | null => {
  const [scanResult, setScanResult] = useState<ExpandScanResult | null>(null);
  const [activeNav, setActiveNav] = useState<string | null>(TABS_NAME.Comment);
  const [errorText, setErrorText] = useError(null);
  const { scanId, patientId } = useParams<{ scanId: string; patientId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [owners, setOwners] = useState<OwnerResponse[] | null>(null);
  const [showInvalidPage, setShowInvalidPage] = useState(false);
  const signal = useSignal();
  const location = useLocation();
  const history = useHistory<{ pathname: string }>();
  const locationFrom = history.location?.state?.pathname;
  const hash = location.hash?.slice(1);

  const getScanDetails = (): void => {
    getScanInfo(+scanId, signal)
      .then(response => setScanResult(updateScan(response)))
      .catch(err => {
        if (!signal.aborted) {
          if (err.status === 404 || err.status === 403) {
            setShowInvalidPage(true);
            return;
          }
          setScanResult(null);
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const chooseTab = (): void => {
    if (!hash) {
      setActiveNav(TABS_NAME.Comment);
      return;
    }

    setActiveNav(hash);
  };

  const getAllOwners = (): void => {
    getOwners(signal)
      .then(res => setOwners(res))
      .catch(e => {
        if (!signal.aborted) {
          setErrorText(e.message);
        }
      });
  };

  useEffect(() => {
    chooseTab();
    getAllOwners();
    getScanDetails();
  }, [scanId]);

  const changeActiveTab = (value: string) => {
    if (value) {
      history.push(`${location.pathname}#${value}`);
      setActiveNav(value);
    } else {
      history.push(location.pathname);
      setActiveNav(TABS_NAME.Comment);
    }
  };

  const changeOwner = (eventKey: string | number): void => {
    setErrorText(null);

    if (eventKey && scanId) {
      const ownerID = eventKey === 'null' ? null : eventKey;
      setNewOwner(+scanId, ownerID as number)
        .then((res: OwnerResponse) => {
          if (!signal.aborted) {
            setScanResult(prev => {
              if (!prev) {
                return null;
              }
              return {
                ...prev,
                OwnerName: res.OwnerName,
              };
            });
          }
        })
        .catch(e => {
          if (!signal.aborted) {
            setErrorText(e);
          }
        });
    }
  };

  if (isLoading) {
    return null;
  }

  if (showInvalidPage) {
    return <InvalidPageComponent id={scanId} content={ContentInvalidScan} />;
  }

  if (!scanResult) {
    return null;
  }

  const goBack = () => {
    if (locationFrom) {
      history.push(locationFrom);
      return;
    }

    if (patientId) {
      history.push(`${PatientsUrl}/${patientId}`);
      return;
    }

    history.push(AllScansUrl);
  };

  return (
    <div className='row-column flex-1'>
      <HeaderComponent goBack={goBack} title={scanResult.FriendlyName || ''} />
      <div className='scan-info-content'>
        <Panel className='scan-info-header' header=''>
          <div className='d-flex pannel-wrap'>
            <div className='justify-content-between'>
              <div>
                <h1 className='h1'>{scanResult.PatientName}</h1>
                <div className='sub-title'>{scanResult.ScanType}</div>
              </div>
              <div>
                <div className='h1'>Scan ID: {scanResult.ScanID}</div>
                <div className='sub-title'>Created on {scanResult.SubmittedOn}</div>
              </div>
              <div>
                <div className='h1'>Assigned Owner: {scanResult.OwnerName || 'No Owner'}</div>
                <div>
                  <Dropdown title='Choose Owner' style={{ marginTop: '-8px' }}>
                    {owners?.map(owner => (
                      <Dropdown.Item key={owner.ID} eventKey={owner.ID} onSelect={changeOwner}>
                        {owner.OwnerName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
              </div>
              <div className='btn-add'>
                <ScanRequestsStatus
                  tooltipText={scanResult.Reason}
                  iconColor={scanResult.InfoColor}
                  placement='topEnd'
                  type={scanResult.StageMFP}
                />
              </div>
            </div>
          </div>
        </Panel>
        <div className='tabs-panel'>
          <Nav appearance='tabs' activeKey={activeNav} onSelect={changeActiveTab}>
            <Nav.Item eventKey={TABS_NAME.Comment}>Comments</Nav.Item>
            <Nav.Item eventKey={TABS_NAME.Report}>Reports</Nav.Item>
            <Nav.Item eventKey={TABS_NAME.Details}>Scan Request Details</Nav.Item>
            <Nav.Item eventKey={TABS_NAME.Scheduling}>Scheduling Information</Nav.Item>
            <Nav.Item eventKey={TABS_NAME.Tasks}>Tasks</Nav.Item>
          </Nav>
        </div>
        <div className='tab-content'>
          <Panel header={<></>}>
            {activeNav === TABS_NAME.Comment && <ScanCommentsTabComponent scanId={Number(scanId)} />}
            {activeNav === TABS_NAME.Report && (
              <ScanReportsTabComponent
                scanId={Number(scanId)}
                reportCollectionStatus={scanResult.ReportCollectionStatus}
                additionalNote={scanResult.ExternalReportCollectionStatusNotes}
              />
            )}
            {activeNav === TABS_NAME.Details && <ScanDetailsTabComponent scanResult={scanResult} />}
            {activeNav === TABS_NAME.Scheduling && <ScanSchedulingTabComponent />}
            {activeNav === TABS_NAME.Tasks && <ScanTasksTabComponent scanId={Number(scanId)} />}
          </Panel>
        </div>
        {errorText && <ErrorMessageComponent errorMessage={errorText} />}
      </div>
    </div>
  );
};

export default ScanInfoComponent;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Panel } from 'rsuite';
import TableComponent from '../../components/table-component';
import { useHistory } from 'react-router-dom';
import { columns, initDataForFilter, searchBarColumns } from './AllCommentsUtil';
import { useLocation } from 'react-router';
import { convertToQueryString, getUrlParams, updateData } from '../../utils/GeneralUtil';
import SearchBarComponent from '../../components/search-bar-component';
import { RowDataType, SortDataType } from '../../components/table-component/interfaces';
import { FilterType, Comment, FilterDataType } from './interfaces';
import useDynamicTableHeight from '../../utils/useDynamicTableHeight';
import { PageSpacingTable } from '../../enum/TableActionEnum';
import CreatScanButton from '../../components/new-scan-button';
import { getCommentBySearchQuery } from '../../api/search/SearchBarAPI';
import { getCommentsList } from '../../api/comments/CommentsAPI';
import FilterCommentsComponent from './FilterCommentsModal';
import CustomPagination from '../../components/custom-pagination';
import LoaderComponent from '../../components/loader-component';
import CommentModal from './comment-modal';
import { SelectOption } from '../../models/General';
import { getSelectOptions } from '../../api/filters/FiltersAPI';
import { convertToLocalDate, transformDate, transformToLocalDateWithTime } from '../../utils/TimeUtil';
import { CommentsFilterData } from '../../api/filters/interfaces';
import { useSignal } from '../../utils/UseSignal';
import { AllCommentsUrl } from '../../config/UrlsConfig';
import useDefaultColumnWidth from '../../utils/UseColumnWidth';

const AllCommentsComponent: React.FunctionComponent = () => {
  const params = new URLSearchParams(useLocation().search);
  const paramsObj = getUrlParams(params?.toString());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePage, setActivePage] = useState(+paramsObj?.currentPage || 1);
  const [data, setData] = useState<Comment[]>([]);
  const [pages, setPages] = useState<number>(0);
  const [numShow, setNumShow] = useState<number>(+paramsObj?.perPage || 25);
  const [filteredTotal, setFilteredTotal] = useState<number>(0);
  const [filerOpened, setFilerOpened] = useState<boolean>(false);
  const [currentScanId, setCurrentScanId] = useState<number>(0);
  const [scanDetailModalShow, setScanDetailModalShow] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<Record<string, SelectOption[]>>({
    scans: [],
    scanStatuses: [],
    patients: [],
    owners: [],
  });
  const [filters, setFilters] = useState<string>(
    convertToQueryString({
      CreatedFrom: paramsObj?.CreatedFrom,
      CreatedTo: paramsObj?.CreatedTo,
      patientIds: paramsObj?.patientIds,
      ownerIds: paramsObj?.ownerIds,
      srIds: paramsObj?.srIds,
      enterIds: paramsObj?.enterIds,
      status: paramsObj?.status,
    }) || '',
  );
  const [sort, setSort] = useState<SortDataType>({});

  const history = useHistory();

  const { navRef, size } = useDynamicTableHeight(PageSpacingTable.IsSearchPagination, 535);
  const signal = useSignal();

  const initFilterData = useRef<FilterDataType>(initDataForFilter);
  const total = useRef<number>(0);
  const { panelRef, defaultWidth, calculateColumnWidth } = useDefaultColumnWidth(columns.length);

  const getAllComments = () => {
    setIsLoading(true);

    getCommentsList(
      {
        filter: filters,
        page: activePage,
        perPage: numShow,
        field: sort.field,
        order: sort.order,
      },
      signal,
    )
      .then(result => {
        if (result.Elements.length) {
          const preparedData = updateData(result.Elements, { DOB: transformDate, Created: transformToLocalDateWithTime });
          setData(preparedData);
        } else {
          setData([]);
          calculateColumnWidth();
        }

        total.current = result.Info.TotalCount;
        setFilteredTotal(result.Info.FilteredTotalCount);
        setPages(result.Info.PageCount);

        if (activePage > result.Info.PageCount) {
          setActivePage(1);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          total.current = 8;
          setFilteredTotal(8);
          setPages(0);
          setData([]);
          calculateColumnWidth();
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      });
  };

  const getFilterData = () => {
    getSelectOptions<CommentsFilterData>(['Scans', 'Patients', 'CommentOwners'], signal)
      .then(res => {
        const filledScansArray: SelectOption[] = [];
        const filledPatientsArray: SelectOption[] = [];
        const filledOwnersArray: SelectOption[] = [];

        Object.values(res.CommentOwners).map(el => {
          filledOwnersArray.push({
            label: el.OwnerName,
            value: el.ID,
          });
        });

        Object.values(res.Patients).map(el => {
          filledPatientsArray.push({
            label: el.FirstName + ' ' + el.LastName,
            value: el.UserID as number,
          });
        });

        Object.values(res.Scans).map(el => {
          filledScansArray.push({
            label: String(el.ScanID),
            value: el.ScanID,
          });
        });

        setFilterData({
          scans: filledScansArray,
          patients: filledPatientsArray,
          owners: filledOwnersArray,
        });
      })
      .catch(() => {
        if (!signal.aborted) {
          setFilterData({
            scans: [],
            patients: [],
            owners: [],
          });
        }
      });
  };

  useEffect(() => {
    let queryString = `currentPage=${activePage}&perPage=${numShow}`;

    if (filters) {
      queryString += `&${filters}`;
    }

    history.push(`/comments?${queryString}`);

    getAllComments();
  }, [numShow, filters, activePage, sort]);

  useEffect(() => {
    if (paramsObj) {
      initFilterData.current = {
        from: paramsObj?.CreatedFrom ? new Date(paramsObj.CreatedFrom) : initFilterData.current.from,
        to: paramsObj?.CreatedTo ? new Date(paramsObj.CreatedTo) : initFilterData.current.to,
        ownerIds: paramsObj?.ownerIds ? paramsObj.ownerIds.split(',').map((i: string) => +i) : initFilterData.current.ownerIds,
        patientIds: paramsObj?.patientIds ? paramsObj.patientIds.split(',').map((i: string) => +i) : initFilterData.current.patientIds,
        scanIds: paramsObj?.srIds ? paramsObj.srIds.split(',').map((i: string) => +i) : initFilterData.current.scanIds,
        commentOwnerIds: paramsObj?.enterIds
          ? paramsObj.enterIds.split(',').map((i: string) => +i)
          : initFilterData.current.commentOwnerIds,
        scanStatusesIds: paramsObj?.status ? paramsObj.status.split(',').map((i: string) => +i) : initFilterData.current.scanStatusesIds,
      };
    }

    getFilterData();
  }, []);

  const handleSelect = (page: number) => {
    setActivePage(page);
  };

  const handleRowClickSearch = (rowData: RowDataType): void => {
    const { TestRequestID } = rowData;
    openScanDetailModal(TestRequestID as number);
  };

  const clickSelectShow = (value: number) => {
    setNumShow(value);
    setActivePage(1);
  };

  const applyFilters = (data: FilterDataType) => {
    if (data) {
      if (!Object.keys(data).length) {
        setFilters('');
        setActivePage(1);
        initFilterData.current = initDataForFilter;
        setFilerOpened(false);
        return;
      }

      const newData: FilterType = {
        CreatedFrom: data.from ? convertToLocalDate(data.from, 'YYYY-MM-DD') : undefined,
        CreatedTo: data.to ? convertToLocalDate(data.to, 'YYYY-MM-DD') : undefined,
        ownerIds: data.ownerIds,
        srIds: data.scanIds,
        patientIds: data.patientIds,
        enterIds: data.commentOwnerIds,
        status: data?.scanStatusesIds,
      };

      const filter = convertToQueryString(newData);
      setFilters(filter);
      setActivePage(1);
      initFilterData.current = data;
    }

    setFilerOpened(false);
  };

  const openScanDetailModal = (scanId: number) => {
    setCurrentScanId(scanId);
    setScanDetailModalShow(true);
  };

  const onCloseDetailModal = (needUpdate: boolean) => {
    if (needUpdate) {
      getAllComments();
    }

    setScanDetailModalShow(false);
  };

  const filterCuttonText = useMemo(
    () => `Showing ${filteredTotal !== total.current ? `${filteredTotal} of` : 'All'} ${total.current} comments`,
    [filteredTotal, total.current],
  );

  return (
    <>
      <div className='page-content-wrap page-comments-content'>
        <div className='nav-top_wrap' ref={navRef}>
          <div className='nav-top row'>
            <h1>Comments</h1>
          </div>
          <div className='row justify-content-between_align-center mb2'>
            <div className='input-search'>
              {
                <SearchBarComponent
                  handleRowClick={handleRowClickSearch}
                  placeholderText={'Search for Date of birth, Patient Name, Scan Request ID, Messages'}
                  columns={searchBarColumns}
                  filters={filters}
                  searchMethod={getCommentBySearchQuery}
                  searchText={'Search for Date of birth, Patient Name, Scan Request ID, Messages'}
                />
              }
            </div>
            <button className='filter-btn' onClick={() => setFilerOpened(true)}>
              {filterCuttonText}
            </button>
            <button className='clear-filter-btn' onClick={() => applyFilters({})} disabled={filters === ''}>
              clear&nbsp;all&nbsp;filters
            </button>
            <div className='btn-add'>
              <CreatScanButton className='rs-btn rs-btn-model btn-w-lg' buttonText='+ Request a New Scan' goBackLink={AllCommentsUrl} />
            </div>
          </div>
        </div>
        <Panel className='patient-panel' ref={panelRef}>
          <div className='d-flex pannel-wrap'>
            <div className='justify-content-end align-items-center'>
              <Dropdown activeKey={String(numShow)} title={`Show ${numShow} results per page`}>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='25'>
                  Show 25 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='50'>
                  Show 50 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='75'>
                  Show 75 results per page
                </Dropdown.Item>
                <Dropdown.Item onSelect={clickSelectShow} eventKey='100'>
                  Show 100 results per page
                </Dropdown.Item>
              </Dropdown>
              <div className='number-pages'>
                {filteredTotal
                  ? `${numShow * (activePage - 1) + 1} - ${numShow * (activePage - 1) + data.length} of ${filteredTotal}`
                  : '0 - 0 of 0'}
              </div>
              <CustomPagination
                className='paging'
                hidden={pages <= 1 && data.length <= numShow}
                disabled={isLoading ? true : undefined}
                pages={pages}
                activePage={activePage}
                onSelect={(page: number) => handleSelect(page)}
                prev
                last
                next
                first
                size='lg'
                maxButtons={3}
                ellipsis
                boundaryLinks
              />
            </div>
          </div>
          {filerOpened && (
            <FilterCommentsComponent
              applyFilters={applyFilters}
              filterData={filterData}
              initData={initFilterData.current}
              onClose={() => setFilerOpened(false)}
            />
          )}
          {isLoading ? (
            <LoaderComponent className={'loader-block-center'} />
          ) : (
            <TableComponent
              columns={columns}
              data={data}
              sort={sort}
              onSort={(sortData: SortDataType) => setSort(sortData)}
              onRowClick={(rowData: Comment) => openScanDetailModal(rowData.TestRequestID)}
              noLastCellIcon
              customHeight={size}
              defaultWidth={defaultWidth}
              isRemove={false}
              wordWrap={true}
              isEdit={false}
            />
          )}
        </Panel>
      </div>
      {scanDetailModalShow && <CommentModal scanId={currentScanId} onClose={onCloseDetailModal} />}
    </>
  );
};

export default AllCommentsComponent;

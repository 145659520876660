import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/logo_main.svg';
import LoginDropdownComponent from '../login-dropdown';
import { SettingsUrl, DashboardUrl } from '../../config/UrlsConfig';
import NavigationListWrapper from './NavigationList';
import InfoModalComponent from '../modals/info-modal';
import { rememberPreviousLink } from '../../utils/DeepLinkingUtil';
import { AutorizedWrap } from './interfaces';
import BannerComponent from '../banner';
import { UserContext, UserContextType } from '../../contexts/UserContext';
import { CHARGBEE_ACTIVE } from '../../config/FrontConfig';

const DefaultAuthorizedWrapper: React.FunctionComponent<AutorizedWrap> = props => {
  const { leave, children, isAdmin, isReload, changeReload, showSidebar, showLoginDropdown } = props;
  const [sessionExpiredModalShow, setSessionExpiredModalShow] = useState<boolean>(false);
  const currentUser = useContext<UserContextType>(UserContext);
  const history = useHistory();
  const url = isAdmin ? SettingsUrl : DashboardUrl;

  if (history && history.location && history.location.pathname.indexOf('login') < 0) {
    localStorage.setItem('route', history.location.pathname);
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', sessionExpiredHandler);
    return () => {
      window.removeEventListener('unhandledrejection', sessionExpiredHandler);
    };
  }, []);

  useEffect(() => {
    if (isReload) {
      const path = localStorage.getItem('route');
      const currentUrl: string = isAdmin ? url : path || DashboardUrl;
      checkLocation(currentUrl);
    }
  }, [isReload]);

  const checkLocation = (location: string) => {
    if (isReload && history.location.pathname.indexOf(location) === -1) {
      setTimeout(() => {
        changeReload(false);
      }, 500);
    }
  };

  const sessionExpiredHandler = (event: PromiseRejectionEvent) => {
    if (event.reason?.status === 401 && !sessionExpiredModalShow) {
      setSessionExpiredModalShow(true);
    }
  };

  const closeSessinExpiredModal = (value: boolean) => {
    setSessionExpiredModalShow(value);
    localStorage.clear();
    rememberPreviousLink(history.location);
    window.location.href = '/login';
  };

  const poID = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return currentUser.PO.ID || 'No ID provided';
    }
    return 'No ID provided';
  }, [currentUser]);

  const IsChargebeeUsed = useMemo(() => {
    if (currentUser && currentUser.PO) {
      return !!currentUser.PO.IsChargebeeUsed;
    }
    return false;
  }, [currentUser]);

  return (
    <main className='main'>
      <section className='page-section'>
        {CHARGBEE_ACTIVE && IsChargebeeUsed && <BannerComponent />}
        <div className='container'>
          <div className='row'>
            {showSidebar && (
              <aside className='sidebar'>
                <div className='sidebar-logo'>
                  <img src={Logo} alt='Medmo' width='93' height='25' />
                </div>
                <span className='sidebar-po-id'>ACCT#: {poID}</span>
                <NavigationListWrapper {...props} />
              </aside>
            )}
            <div className='page-content'>{children}</div>
            {showLoginDropdown && <LoginDropdownComponent logout={leave} {...props} />}
            {sessionExpiredModalShow && (
              <InfoModalComponent
                onClose={closeSessinExpiredModal}
                type='info'
                texts={{
                  title: 'Your session has expired',
                  content: 'You have been logged out due to inactivity, please log in again.',
                  buttonText: 'OK',
                }}
              />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default DefaultAuthorizedWrapper;
